import { MenuItemOption } from "./MenuItemOption";
import { Model } from "./Model";

export interface MenuItem extends Model {
    name: string;
    description: string;
    picture: string;
    displayOnSite: boolean;
    availableToBuy: boolean;
    price: number;
    dealPrice: number;
    dealStart: string;
    dealEnd: string;
    rank: number;
    requiredOptions: string;
    optionalExtras: string;
    selectedTags: string;
    menuCategoryId: number;
    //menuCategory: MenuCategory | null;
}

export class MenuItem implements MenuItem {

	constructor(data?: Partial<MenuItem>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}