import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";
import { Toast } from "primereact/toast";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { MenuCategory, MenuItem, MenuItemOption, RestaurantSettings } from "src/app/interfaces/Index";
import { RestaurantSettingsService, FileService, MenuItemService, MenuCategoryService } from "src/app/services/Index";
import { colours } from "src/app/Styles";
import { DataTable, DataTableRowEditCompleteParams } from "primereact/datatable";
import { Column, ColumnEditorOptions } from "primereact/column";
import { faPencil, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";
import { Guid } from "guid-typescript";
import { Link, Navigate } from "react-router-dom";

type Props = {};
type State = {
	
	categories: MenuCategory[];
	isLoading: boolean;
	menuItem: MenuItem;
	menuItemId: Guid;
	menuItemOriginal: MenuItem;
	optionalExtras: MenuItemOption[];
	requiredOptions: MenuItemOption[];
	selectedDay: string;
	selectedOptionalExtra: MenuItemOption;
	selectedRequiredOption: MenuItemOption;
	showDeleteModal: boolean;
	showDeleteSelectionModal: boolean;
	showOptionalExtraModal: boolean;
	showRequiredOptionModal: boolean;
	submitted: boolean;
};
export class MenuItems extends React.Component<Props, State> {
	availDays = [
		{ value: "1", label: "Sunday" },
		{ value: "2", label: "Monday" },
		{ value: "3", label: "Tuesday" },
		{ value: "4", label: "Wednesday" },
		{ value: "5", label: "Thursday" },
		{ value: "6", label: "Friday" },
		{ value: "7", label: "Saturday" }
	];
	toast: any;
	

	constructor(props: Props) {
		super(props);
		this.state = { categories: [], isLoading: false, menuItem: new MenuItem, menuItemId: Guid.parse(location.href.substring(location.href.lastIndexOf("/") + 1)), menuItemOriginal: new MenuItem(), optionalExtras: [], requiredOptions: [], selectedDay: "", selectedOptionalExtra: new MenuItemOption, selectedRequiredOption: new MenuItemOption, showDeleteModal: false, showDeleteSelectionModal: false, showOptionalExtraModal: false, showRequiredOptionModal: false, submitted: false };
	}

	componentDidMount() {
		this.getMenuCategories();
		this.getMenuItem();
	}

	deleteOptionFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="No" onClick={() => this.setState({ showDeleteModal: false })}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Yes" onClick={(data) => this.deleteRequiredOptionConfirm(data)}></Button>
			</>
		);
	}

	deleteRequiredOptionFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="No" onClick={() => this.setState({ showDeleteModal: false })}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Yes" onClick={(data) => this.deleteRequiredOptionConfirm(data)}></Button>
			</>
		);
	}

	async deletedOptionConfirm(rowData: any) {
		// const res = await (await RequiredOptionService.deleteSingleById(this.state.selectedRequiredOption.id)).json();
		// if (res && res.error) {
		// 	this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
		// 	this.setState({ isLoading: false });
		// } else {
		// 	this.setState({ isLoading: false, selectedRequiredOption: new RequiredOption(), showDeleteModal: false })
		// 	this.toast.show({ closable: false, detail: "MenuItem Successfully Archived", life: 5000, severity: "warn", summary: "MenuItem Archived" });
		// 	this.getMenuItem();
		// };

		var options = this.state.requiredOptions;
	}

	async deleteRequiredOptionConfirm(rowData: any) {
		// const res = await (await RequiredOptionService.deleteSingleById(this.state.selectedRequiredOption.id)).json();
		// if (res && res.error) {
		// 	this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
		// 	this.setState({ isLoading: false });
		// } else {
		// 	this.setState({ isLoading: false, selectedRequiredOption: new RequiredOption(), showDeleteModal: false })
		// 	this.toast.show({ closable: false, detail: "MenuItem Successfully Archived", life: 5000, severity: "warn", summary: "MenuItem Archived" });
		// 	this.getMenuItem();
		// };
	}

	async getMenuCategories() {
		const res = await (await MenuCategoryService.getAll()).json();
		if (res && res.error) this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
		this.setState({ categories: res});
	}

	async getMenuItem() {
		const res = await (await MenuItemService.getSingleByLookupId(this.state.menuItemId)).json();
		if (res && res.error) this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
		let reqs: MenuItemOption[];
		reqs = [];
		if(res.requiredOptions != "[]")
		{
			reqs = JSON.parse(res.requiredOptions);	
		}
		this.setState({ menuItem: res, requiredOptions: reqs});
	}

	inputChange(e: any, label: string) {
		const val = e.value !== undefined ? e.value : (e.target !== undefined && e.target.value !== undefined) ? e.target.value : "";
		let menuItem = { ...this.state.menuItem };
		// @ts-ignore // Required to be able to use a generic function to change the values
		menuItem[label] = val;
		this.setState({ menuItem });
	}

	optionChange(e: any, label: string) {
		const val = e.value !== undefined ? e.value : (e.target !== undefined && e.target.value !== undefined) ? e.target.value : "";
		let selectedOptionalExtra = { ...this.state.selectedOptionalExtra };
		// @ts-ignore // Required to be able to use a generic function to change the values
		selectedOptionalExtra[label] = val;
		this.setState({ selectedOptionalExtra });
	}
	
	requiredChange(e: any, label: string) {
		const val = e.value !== undefined ? e.value : (e.target !== undefined && e.target.value !== undefined) ? e.target.value : "";
		let selectedRequiredOption = { ...this.state.selectedRequiredOption };
		// @ts-ignore // Required to be able to use a generic function to change the values
		selectedRequiredOption[label] = val;
		this.setState({ selectedRequiredOption });
	}

	openingTimeInfoFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => this.setState({ selectedRequiredOption: new MenuItemOption(), showRequiredOptionModal: false })}></Button>
				<Button className="p-button-text" icon="pi pi-check" label={this.state.selectedRequiredOption.id === undefined || this.state.selectedRequiredOption.id === -1 ? "Create" : "Save"} onClick={() => this.saveRequiredOption()}></Button>
			</>
		);
	}

	render() {
		return (
			<>
				<div className="card p-fluid" style={{ paddingBottom: "0.5rem", paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "1rem" }}>
					<Row>
						<Col>
					<h5 style={{paddingTop:10}}>Menu Item</h5>
					</Col>
					<Col>
					<Link to='/admin/menu'><Button disabled={this.state.isLoading === true} iconPos="right" icon="pi pi-arrow-left" label="Return to menu" style={{ backgroundColor: colours.buttons.archive, borderColor: colours.buttons.cancel, marginRight: 10, width: 200, float:"right" }}></Button></Link>
					</Col>
					</Row>
					<TabView className="tabview-header-icon">
									<TabPanel header="Basic Information" leftIcon="pi pi-info-circle">
										<Row>
											<div className="field">
												<label htmlFor="name">Name</label>
												<InputText autoFocus className={classNames({ "p-invalid": this.state.submitted && !this.state.menuItem.name })} id="name" onChange={(e) => this.inputChange(e, "name")} required value={this.state.menuItem.name} />
												{this.state.submitted && !this.state.menuItem.name &&
													<small className="p-invalid">Name is required.</small>
												}
											</div>
										</Row>
										<Row>
											<div className="field">
												<label htmlFor="description">Description</label>
												<InputTextarea id="description" onChange={(e) => this.inputChange(e, "description")} required rows={3} value={this.state.menuItem.description} />
											</div>
										</Row>
										<Row>
						<Col>
							<div className="field" style={{ marginBottom: 10 }}>
								<label htmlFor="cat">Dish Category</label>
								<Dropdown onChange={(e) => this.setState({menuItem: {...this.state.menuItem, menuCategoryId: e.value}})} options={this.state.categories} style={{width: "100%"}} value={this.state.menuItem.menuCategoryId} optionLabel="name" optionValue="id"/>
							</div>
						</Col>
					</Row>
										<Row>
											<Col>
												<div className="field">
													<label htmlFor="rank">Rank</label>
													<InputNumber id="rank" onChange={(e) => this.inputChange(e, "rank")} required value={this.state.menuItem.rank} />
												</div>
											</Col>
											<Col>
												<div className="field">
													<label htmlFor="displayOnSite" className="switchSpacing">Display on site</label>
													<InputSwitch id="displayOnSite" onChange={(e) => this.inputChange(e, "displayOnSite")} required checked={this.state.menuItem.displayOnSite} />
												</div>
											</Col>
											<Col>
												<div className="field">
													<label htmlFor="availableToBuy" className="switchSpacing">Available to buy</label>
													<InputSwitch id="availableToBuy" onChange={(e) => this.inputChange(e, "availableToBuy")} required checked={this.state.menuItem.availableToBuy} />
												</div>
											</Col>
										</Row>
									</TabPanel>
									<TabPanel header="Required Options" leftIcon="pi pi-plus">
										<Row style={{marginBottom: 10}}>
											
											<Col><Button iconPos="right" label="Add Option" icon="pi pi-plus" loading={this.state.isLoading} style={{ backgroundColor: colours.buttons.save, borderColor: colours.buttons.save, width: 250, float: "right" }} onClick={() => this.setState({ selectedRequiredOption: new MenuItemOption(), showRequiredOptionModal: true })}></Button></Col>
										</Row>
										<Row>
												<Col>
													<DataTable id="orderItems" dataKey="id" scrollable scrollHeight="350px" style={{marginBottom: 10}} value={this.state.requiredOptions}>
														<Column field="line" header="Line" sortable></Column>
														<Column field="name" header="Name" sortable></Column>
														<Column field="price" header="Price" sortable></Column>
														<Column style={{ width: 75 }} body={(rowData) => (
								<div className="actions">
									<Button onClick={() => this.renderDialog(rowData)} style={{ backgroundColor: colours.buttons.edit, borderColor: colours.buttons.edit, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30 }}>
										<FontAwesomeIcon icon={faPencil} style={{ color: colours.white }} />
									</Button>
									<Button onClick={() => this.setState({ selectedRequiredOption: rowData, showDeleteModal: true })} style={{ backgroundColor: colours.buttons.delete, borderColor: colours.buttons.delete, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30 }}>
										<FontAwesomeIcon icon={faTrashAlt} style={{ color: colours.black }} />
									</Button>
								</div>
							)}></Column>
													</DataTable>
												</Col>
											</Row>
											{/* <Row>
												<Col>
													<label style={{marginTop: ".5rem", marginBottom: "1rem"}} htmlFor="optionalExtras">Optional Extras</label>
													<DataTable id="optionalExtras" scrollable scrollHeight="350px" style={{marginBottom: 10}} value={this.state.menuItem.optionalExtras}>
													<Column field="line" header="Line" sortable></Column>
														<Column field="name" header="Name" sortable></Column>
														<Column field="price" header="Price" sortable></Column>
													</DataTable>
												</Col>
											</Row> */}
									</TabPanel>
									<TabPanel header="Photo" leftIcon="pi pi-image">
										<Row style={{marginBottom:10}}><text>Photo</text></Row>
										<Row>
											<Col>
												<div style={{backgroundColor: "#EDF1F5", width:356, height:356, padding:25, borderStyle: "dashed", borderColor: "#708DA8", borderWidth:3, borderRadius:10}}>{this.state.menuItem.picture &&
													<img alt={this.state.menuItem.picture} className="mt-0 mx-auto block" src={this.state.menuItem.picture} width={300} />
												}</div>
											</Col>
											<Col>
												<div className="field" style={{ marginBottom: 10 }}>
													<div style={{marginBottom:20}}><text>Photos help customers decide what to order and can increase sales.</text></div>
													<div style={{marginBottom:20}}><text>For the best results we recommend using an image with a 1:1 ratio (square)</text></div>
													<div style={{marginBottom:20}}><text>File requirement: JPG, PNG, GIF, or WEBP up to 10MB. Minimum pixels required: 320 for width and height.</text></div>
													<FileUpload accept="image/*" auto mode="basic" customUpload name="picture" style={{ marginBottom: 10 }} uploadHandler={(data) => this.uploadImage(data, "picture")}></FileUpload>
												</div>
											</Col>
										</Row>
									</TabPanel>
									<TabPanel header="Pricing" leftIcon="pi pi-money-bill">
										<Row>
											<Col>
												<div className="field" style={{ marginBottom: 10 }}>
													<label htmlFor="price">Price</label>
													<InputNumber currency="GBP" id="price" locale="en-GB" mode="currency" onValueChange={(e) => this.inputChange(e, "price")} value={this.state.menuItem.price} />
												</div>
											</Col>
											<Col>
												<div className="field" style={{ marginBottom: 10 }}>
													<label htmlFor="dealPrice">Deal Price</label>
													<InputNumber currency="GBP" id="dealPrice" locale="en-GB" mode="currency" onValueChange={(e) => this.inputChange(e, "dealPrice")} value={this.state.menuItem.dealPrice} />
												</div>
											</Col>
										</Row>
										<Row>
											<Col>
												<div className="field" style={{ marginBottom: 10 }}>
													<label htmlFor="dealStart">Deal Start</label>
													<Calendar id="dealStart" onChange={(e) => this.inputChange(e, "dealStart")} required value={new Date(this.state.menuItem.dealStart)} showIcon showButtonBar />
												</div>
											</Col>
											<Col>
												<div className="field" style={{ marginBottom: 10 }}>
													<label htmlFor="dealEnd">Deal End</label>
													<Calendar id="dealEnd" onChange={(e) => this.inputChange(e, "dealEnd")} required value={new Date(this.state.menuItem.dealEnd)} showIcon showButtonBar />
												</div>
											</Col>
										</Row>
									</TabPanel>
								</TabView>
					<Row>
						<Col>
							<div className="field" style={{ marginBottom: 10, textAlign: "right" }}>
								<Button disabled={JSON.stringify(this.state.menuItem) === JSON.stringify(this.state.menuItemOriginal) || this.state.isLoading === true} iconPos="right" label="Discard" style={{ backgroundColor: colours.buttons.cancel, borderColor: colours.buttons.cancel, marginRight: 10, width: 150 }} onClick={() => this.setState({ menuItem: this.state.menuItemOriginal })}></Button>
								<Button disabled={JSON.stringify(this.state.menuItem) === JSON.stringify(this.state.menuItemOriginal)} iconPos="right" label="Save" loading={this.state.isLoading} style={{ backgroundColor: colours.buttons.save, borderColor: colours.buttons.save, width: 150 }} onClick={() => this.saveChanges()}></Button>
							</div>
						</Col>
					</Row>
				</div>
				<Toast ref={(el) => this.toast = el}></Toast>
				<Dialog className="p-fluid" footer={() => this.openingTimeInfoFooter()} header="Required Option" modal onHide={() => this.setState({ showRequiredOptionModal: false })} style={{ width: "75%", height: "85vh" }} visible={this.state.showRequiredOptionModal}>
					<Row>
						<Col>
							<div className="field" style={{ marginBottom: 10 }}>
								<label htmlFor="line">Line Number</label>
								<InputNumber id="line" readOnly onChange={(e) => this.requiredChange(e, "line")} value={this.state.selectedRequiredOption.line || (this.state.requiredOptions == undefined ? 0 : (this.state.requiredOptions.length == 0 ? 1 : this.state.requiredOptions.length))} />
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="field" style={{ marginBottom: 10 }}>
								<label htmlFor="name">Option Name</label>
								<InputText id="name" readOnly={true} onChange={(e) => this.requiredChange(e, "name")} value={this.state.selectedRequiredOption.name || ""} />
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="field" style={{ marginBottom: 10 }}>
								<label htmlFor="price">Price</label>
								<InputNumber id="price" onChange={(e) => this.requiredChange(e, "price")} value={this.state.selectedRequiredOption.price || 0} />
							</div>
						</Col>
					</Row>
				</Dialog>
				<Dialog className="p-fluid" footer={() => this.openingTimeInfoFooter()} header="Optional Extras" modal onHide={() => this.setState({ showOptionalExtraModal: false })} style={{ width: "75%", height: "85vh" }} visible={this.state.showOptionalExtraModal}>
					<Row>
						<Col>
							<div className="field" style={{ marginBottom: 10 }}>
								<label htmlFor="line">Line Number</label>
								<InputNumber id="line" readOnly onChange={(e) => this.optionChange(e, "line")} value={this.state.selectedOptionalExtra.line || (this.state.optionalExtras == undefined ? 0 : (this.state.optionalExtras.length == 0 ? 1 : this.state.optionalExtras.length))} />
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="field" style={{ marginBottom: 10 }}>
								<label htmlFor="name">Option Name</label>
								<InputText id="name" readOnly={true} onChange={(e) => this.optionChange(e, "name")} value={this.state.selectedRequiredOption.name || ""} />
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="field" style={{ marginBottom: 10 }}>
								<label htmlFor="price">Price</label>
								<InputNumber id="price" onChange={(e) => this.optionChange(e, "price")} value={this.state.selectedRequiredOption.price || 0} />
							</div>
						</Col>
					</Row>
				</Dialog>
				<Dialog footer={() => this.deleteRequiredOptionFooter()} header="Confirm" modal onHide={() => this.setState({ showDeleteModal: false })} style={{ width: 550 }} visible={this.state.showDeleteModal}>
					<div className="d-flex align-items-center justify-content-center">
						<i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem", marginRight: "0.5rem" }} />
						{this.state.selectedRequiredOption &&
							<span>Are you sure you want to archive <b>{this.state.selectedRequiredOption.name}</b>?</span>
						}
					</div>
				</Dialog>
			</>
		);
	}

	renderAvailableDays() {
		return (
			<>
				<Row>
					<Col>
						<DataTable id="openingTime" scrollable scrollHeight="350px" style={{ marginBottom: 10 }} value={this.state.requiredOptions}>
						<Column field="startTime" header="Line" sortable></Column>
							<Column field="startTime" header="Name" sortable></Column>
							<Column field="endTime" header="Price" sortable></Column>
							<Column style={{ width: 75 }} body={(rowData) => (
								<div className="actions">
									<Button onClick={() => this.renderDialog(rowData)} style={{ backgroundColor: colours.buttons.edit, borderColor: colours.buttons.edit, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30 }}>
										<FontAwesomeIcon icon={faPencil} style={{ color: colours.white }} />
									</Button>
									<Button onClick={() => this.setState({ selectedRequiredOption: rowData, showDeleteModal: true })} style={{ backgroundColor: colours.buttons.delete, borderColor: colours.buttons.delete, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30 }}>
										<FontAwesomeIcon icon={faTrashAlt} style={{ color: colours.black }} />
									</Button>
								</div>
							)}></Column>
						</DataTable>
					</Col>
				</Row>
			</>
		)
		// if (!this.state.chefProfile) return;
		// return this.state.chefProfile.availabilityArray.map((data: any, i: number) => {
		// 	return (
		// 		<Row key={i}>
		// 			<Col style={{alignItems: "center", display: "flex", height: 30}}>
		// 				<div style={{fontSize: 14, width: 85}}>{data.day}</div>
		// 				<InputSwitch checked={data.isAvailable} onChange={(e) => this.updateAvailableDays(i, e.value)} style={{marginRight: 15}}></InputSwitch>
		// 				<Calendar disabled={data.isAvailable === false} hourFormat="24" onChange={(e) => this.updateAvailableDayTime(i, "startTime", e.value)} showTime style={{height: 28, width: 48, marginRight: 5}} timeOnly value={new Date(data.startTime)}></Calendar>
		// 				-
		// 				<Calendar disabled={data.isAvailable === false} hourFormat="24" onChange={(e) => this.updateAvailableDayTime(i, "endTime", e.value)} showTime style={{height: 28, width: 48, marginLeft: 5}} timeOnly value={new Date(data.endTime)}></Calendar>
		// 			</Col>
		// 		</Row>
		// 	);
		// });
	}

	renderDay(e: any) {
		return this.availDays.find(x => x.value == e.day)?.label;

	}

	renderDialog(rowData: any) {
		this.setState({ selectedDay: this.renderDay(rowData)!, selectedRequiredOption: rowData, showRequiredOptionModal: true })
	}

	renderFileStatus(file?: string) {
		if (file === null)
			return <Message severity="error" text="File not yet uploaded." />
		else
			return <Message severity="success" text="File uploaded." />
	}

	returnMenu()
	{
		console.log("hit")
		
	}


	async saveChanges() {
		await this.setState({ isLoading: true });
		let tempItem = this.state.menuItem;
		tempItem.requiredOptions = JSON.stringify(this.state.requiredOptions);
		tempItem.optionalExtras = JSON.stringify(this.state.optionalExtras);

		const res = await (await MenuItemService.updateSingleById(1, tempItem)).json();
		if (res && res.error) {
			this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
			this.setState({ isLoading: false });
		} else {
			this.toast.show({ closable: false, detail: "Changes saved successfully", life: 5000, severity: "success" });
			this.setState({ menuItem: res, menuItemOriginal: res, isLoading: false });
		}
	}

	async saveRequiredOption() {
		// await this.setState({ isLoading: true });
		// const res = await (await RequiredOptionService.updateSingleById(1, this.state.selectedRequiredOption)).json();
		// if (res && res.error) {
		// 	this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
		// 	this.setState({ isLoading: false });
		// } else {
		// 	this.toast.show({ closable: false, detail: "Changes saved successfully", life: 5000, severity: "success" });
		// 	this.setState({ isLoading: false, showRequiredOptionModal: false });
		// 	this.getMenuItem();
		// }
	}

	async uploadImage(data: any, property: string) {
		const containerName = "images";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", data.files[0]);
		uploadData.append("mimeTypeKey", "Images");
		const response = await (await FileService.upload(uploadData)).json();
		let tempChef = { ...this.state.menuItem };
		// @ts-ignore // Required to be able to change the values
		tempChef[property] = `${process.env.REACT_APP_SERVER_STORAGEURL}/${containerName}/${response[0].location}`;
		await this.setState({ menuItem: tempChef });
	}

	updateAvailableDayTime(i: number, property: string, value: any) {
		// const newAvailDays = [...this.state.chefProfile.availabilityArray];
		// const day = {...newAvailDays[i]};
		// // @ts-ignore // Required to be able to change the values
		// day[property] = value;
		// newAvailDays[i] = day;
		// this.setState({chefProfile: {...this.state.chefProfile, availabilityArray: newAvailDays}});
	}

	updateAvailableDays(i: number, value: boolean) {
		// const newAvailDays = [...this.state.chefProfile.availabilityArray];
		// const day = {...newAvailDays[i]};
		// day.isAvailable = value;
		// newAvailDays[i] = day;
		// this.setState({chefProfile: {...this.state.chefProfile, availabilityArray: newAvailDays}});
	}
}